html,
body {
  padding: 0;
  margin: 0;
  color: #292929;
  background-color: #f9f6ee;
  text-align: center;
}
.container {
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* form {
  transition: all 0.5s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
} */
.finder {
  border: 1px solid #fff;
  background-color: #f9f6ee;
  border-radius: 50px;
  padding: 8px;
  box-shadow: 9px 9px 16px rgba(189, 189, 189, 0.6),
    -9px -9px 16px rgba(255, 255, 255, 0.5);
}

.finder_button {
  border-radius: 133px;
  background: #f5eaea;
  box-shadow: 12px 12px 24px #d0c7c7, -12px -12px 24px #ffffff;
}

.finder__outer {
  display: flex;
  width: 600px;
  padding: 1.5rem 2rem;
  border-radius: 50px;
  box-shadow: inset 10px 10px 15px -10px #c3c3c3,
    inset -10px -10px 15px -10px #ffffff;
}

.finder__inner {
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;
}

.finder__form {
  flex: 1;
  height: calc(100% + 3rem);
}

.finder__input {
  height: calc(100% + 3rem);
  border: none;
  background-color: transparent;
  outline: none;
  font-size: 1.5rem;
  letter-spacing: 0.75px;
}
